  function readCookie(name) {
      var nameEQ = name + "=";
      var ca = document.cookie.split(';');
      for(var i=0;i < ca.length;i++) {
          var c = ca[i];
          while (c.charAt(0)==' ') c = c.substring(1,c.length);
          if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
      }
      return null;
  }
  // cookies
  var userip = readCookie("userip");
  if (userip){
      $('#warning_popup').hide();
  }else{
    $('#warning_popup').show();
  }
  $('#warning_popup_close').on('click', function(){
      var now = new Date();
      now.setTime(now.getTime() + 24 * 3600 * 1000);
      ip = $("input[name='ip']").val();
      document.cookie = "userip=" + ip +";path=/;expires="+ now.toUTCString();
      $('#warning_popup').fadeOut();
  });
  $(document).ready(function(){
      "use strict";
      var siteBody = $("body");
      var siteHtml = $("html");


      /* Menu */
      $("#menuOpen button.hamburger").on('click',function (e) {
          $('.main-menu').toggleClass("opened");
          $(this).parent().toggleClass("opened");
          $(this).toggleClass('is-active');
          siteBody.toggleClass('shadow');
          siteHtml.toggleClass('shadow');
      });
      $('.main-menu li.menu-item-has-children').append('<span class="subMenuButton fa fa-angle-down"></span>');
      $('.subMenuButton').on('click',function () {
          var t = $(this);
          if(t.hasClass('open')) {
              t.removeClass('open').prev().slideUp(300);
          } else {
              t.removeClass('open').prev().slideDown(300);
              t.addClass('open');
          }
      });
      // Menu Close
      $(document).mouseup(function (e){
          var div = $(".main-menu,#menuOpen");
          if (!div.is(e.target)
              && div.has(e.target).length === 0) {
              div.removeClass('opened');
              $('.hamburger').removeClass('is-active');
              siteBody.removeClass('shadow');
              siteHtml.removeClass('shadow');
          }
      });

      /* Scroll top */
      $(window).scroll(function(){
          if ($(this).scrollTop() > 100) {
              $(".to-top-btn").addClass('active');
          }
          else {
              $(".to-top-btn").removeClass('active');
          }
      });

      /* Scroll top Click */
      $(".to-top-btn").on("click", function () {
          $('html, body').animate({scrollTop: 0}, 1000);
      });



      // 404 go back
      $('.back').on('click', function(){
          setTimeout('history.back()', 1000);
      });


      // Load More
      $('#rw_show_more').on('click', function (e) {
              e.preventDefault();
              e.stopPropagation();
              var showMore = $('#rw_show_more');
              var loader = $('#loader');
              var maxNumPages = $(this).data('max-pages');
              var queryVars = $(this).data('query-vars');
              var currentPage = $(this).data('current-page');
              var data = {
                  'action': 'loadmore',
                  'query_vars': queryVars,
                  'page' : currentPage
              };
              showMore.hide();
              loader.show();
              $.ajax({
                  url:localizedScript.url,
                  data:data,
                  type:'POST',
                  success:function(data){
                      if( data ) {
                          $('.load-more-wrapper').before(data);
                          showMore.data('current-page',currentPage + 1);
                          showMore.show();
                          loader.hide();
                          if (currentPage === maxNumPages){
                              showMore.parent().hide();
                              showMore.remove();
                              loader.hide();
                          }
                      } else {
                          showMore.parent().hide();
                          showMore.remove();
                          loader.hide();
                      }
                  }
              });
          });

      // Home contact form
      $('#hc_form').on('submit',function(e){
            e.preventDefault();
            var $that = $(this);
            var nonce = $that.find("input[name='hc_nonce']").val();
            var customerEmail = $that.find("input[name='hc_email']").val();
            var mail_pattern=/[0-9a-z_]+@[0-9a-z_-]+\.[a-z]{2,5}/i;
            var check_mail = mail_pattern.test(customerEmail);
            var agreeReceiveEmails = $that.find("input[name='agree_receive_emails']");
            var userName = $that.find("input[name='hc_name']").val();
            if( customerEmail === '' ){
              $that.find("input[name='hc_email']").css({'border':'1px solid red'});
			  $("#hc_email_error").text('Email is required');
               
            }
            else if( check_mail !== true ){
                $that.find("input[name='hc_email']").css({'border':'1px solid red'});
				$("#hc_email_error").text('Not valid email');
                
            }else{
              $that.find("input[name='hc_email']").css({'border':'1px solid white'});
			  $("#hc_email_error").text('');
            }
            if( userName == '' ){
              $that.find("input[name='hc_name']").css({'border':'1px solid red'});
               $("#hc_name_error").text('Name is required');
            }else{
              $that.find("input[name='hc_name']").css({'border':'1px solid white'});
			  $("#hc_name_error").text('');
            }
            if( !agreeReceiveEmails.is(':checked') ){
                $("#hc_agree_receive_emails_error").text('Please confirm agreement');
            }else{
                $("#hc_agree_receive_emails_error").text('');
            }
            // all checks are ok
          
            if( check_mail == true && userName !== '' && agreeReceiveEmails.is(':checked') ){
                $that.find("input[name='hc_email']").css({'border':'1px solid white'});
                $that.find("input[name='hc_name']").css({'border':'1px solid white'});
                $.ajax({
                    type: 'POST',
                    url: localizedScript.url,
                    data:{
                        action: 'home_contact_form',
                        nonce: nonce,
                        user_name: userName,
                        user_email: customerEmail,
                        captcha: grecaptcha.getResponse()
                    },
                    success: function (result) {
                        var res = JSON.parse(result);
                        if(res.status === 'success'){
                            $that[0].reset();
                            grecaptcha.reset();
                            $('.thank-popup-wrapper').fadeIn();
                            setTimeout(function(){$('.thank-popup-wrapper').fadeOut()}, 3000);
                        }
                        else if(res.status === 'user_already_exists'){
                            $("#hc_email_error").text('This email address has already been registered');
                            $("input[name='hc_email']").css({'border':'1px solid red'});
                            grecaptcha.reset();
                        }
                        else if(res.status === 'failed'){
                            $('.recaptcha-error-msg').html('<p>Oops... Validation is not passed!</p>');
                            setTimeout(function(){
                                $('.recaptcha-error-msg').html('');
                            }, 3000);
                        }
                    },
                    error: function(jqxhr, status, exception) {
                        console.log('Exception:', exception);
                    }
                });
            }
        });

        // Contact us contact form + mask
        if( document.getElementById('cu_phone') ){
          document.getElementById('cu_phone').addEventListener('input', function (e) {
            // var x = e.target.value.replace(/\D/g, '').match(/(\d{0,1})(\d{0,3})(\d{0,3})(\d{0,4})/);
             var x = e.target.value.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
             // e.target.value = !x[2] ? x[1] : '+' + x[1] + '-' + x[2] + (x[3] ? '-' + x[3] : '') + (x[4] ? '-' + x[4] : '');
              e.target.value = !x[2] ? x[1] : '' + x[1] + '-' + x[2] + (x[3] ? '-' + x[3] : '');
          });
        }

        $('#cu_form').on('submit',function(e){
              e.preventDefault();
              var $that = $(this);
              var nonce = $that.find("input[name='cu_nonce']").val();
              var customerEmail = $that.find("input[name='cu_email']").val();
              var mail_pattern=/[0-9a-z_]+@[0-9a-z_-]+\.[a-z]{2,5}/i;
              var check_mail = mail_pattern.test(customerEmail);
              var userName = $that.find("input[name='cu_name']").val();
              var userCompany = $that.find("input[name='cu_company']").val();
              var userSubject = $that.find("input[name='cu_subject']").val();
              var userMessage = $that.find("textarea[name='cu_message']").val();
              var userPhone = $that.find("input[name='cu_phone']").val();
              // prev var phone_pattern = /^\+?([0-9]{1})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
              var phone_pattern = /([0-9]{3})[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;

              var check_phone = phone_pattern.test(userPhone);
              if( userPhone !== '' && check_phone !== true ){
                $that.find("input[name='cu_phone']").css({'border':'1px solid red'});
                $("#cu_phone_error").text('Not valid phone');
                setTimeout(function(){$that.find("input[name='cu_phone']")}, 3000);
              }
              else if (userPhone === ''){
                $that.find("input[name='cu_phone']").css({'border':'1px solid red'});
              }
              else{
                $that.find("input[name='cu_phone']").css({'border':'1px solid #F4F4F4'});
                $("#cu_phone_error").text('');
              }
              if( customerEmail === '' ){
                $that.find("input[name='cu_email']").css({'border':'1px solid red'});
                  setTimeout(function(){$that.find("input[name='cu_email']")}, 3000);
                  $('#cu_email_error').text('');
              }
              else if( check_mail !== true ){
                  $that.find("input[name='cu_email']").css({'border':'1px solid red'});
                  setTimeout(function(){$that.find("input[name='cu_email']")}, 3000);
                  $('#cu_email_error').text('Not valid email');
              }else{
                $that.find("input[name='cu_email']").css({'border':'1px solid #F4F4F4'});
                $('#cu_email_error').text('');
              }
              if( userCompany == '' ){
                $that.find("input[name='cu_company']").css({'border':'1px solid red'});
                setTimeout(function(){$that.find("input[name='cu_company']")}, 3000);
              }else{
                  $that.find("input[name='cu_company']").css({'border':'1px solid #F4F4F4'});
              }
              if( userName == '' ){
                $that.find("input[name='cu_name']").css({'border':'1px solid red'});
                setTimeout(function(){$that.find("input[name='cu_name']")}, 3000);
              }else{
                $that.find("input[name='cu_name']").css({'border':'1px solid #F4F4F4'});
              }
              if( userCompany !== '' && check_phone == true && check_mail == true && userName !== '' ){
                  $that.find("input[name='cu_company']").css({'border':'1px solid #F4F4F4'});
                  $that.find("input[name='cu_email']").css({'border':'1px solid #F4F4F4'});
                  $that.find("input[name='cu_name']").css({'border':'1px solid #F4F4F4'});
                  $that.find("input[name='cu_phone']").css({'border':'1px solid #F4F4F4'});
                  $.ajax({
                      type: 'POST',
                      url: localizedScript.url,
                      data:{
                          action: 'contact_us_form',
                          nonce: nonce,
                          user_name: userName,
                          user_email: customerEmail,
                          user_phone: userPhone,
                          user_company: userCompany,
                          user_subject: userSubject,
                          user_message: userMessage,
                          captcha: grecaptcha.getResponse()
                      },
                      success: function (result) {
                          var res = JSON.parse(result);
                          if(res.status === 'success'){
                              $that[0].reset();
                              grecaptcha.reset();
                              $('.thank-popup-wrapper').fadeIn();
                              setTimeout(function(){$('.thank-popup-wrapper').fadeOut()}, 3000);
                          }
                          else if(res.status === 'failed'){
                              $('.recaptcha-error-msg').html('<p>Oops... Validation is not passed!</p>');
                              setTimeout(function(){
                                  $('.recaptcha-error-msg').html('');
                              }, 3000);
                          }
                      },
                      error: function(jqxhr, status, exception) {
                          console.log('Exception:', exception);
                      }
                  });
              }
          });
  });
